/*eslint-disable */

import 'cropperjs/dist/cropper.css';

import VueCropper from 'vue-cropperjs';
import axios from 'axios';
import {bus} from '@/main';
import {
  mapGetters
} from 'vuex';
import root from '@/_helpers/roots';
import toast from '@/services/toast';

let profileAvatarApiUrl = root.profileAvatarApiUrl.link;
export default {
  name: 'user-profile-photo-add',
  components: {
    VueCropper
  },
  props: [],
  data () {
    return {
      selfUserDetailData:{},
      imgSrc:'',
      thumbnail:'',
      avatar:'',
      filename:'',
      userImgLoading:false,
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters',
    ]),
    getSelfUserInfoMethod() {
      return this.selfUserDetailData = this.$store.getters.selfUserDetailGetters.data
    },
    getInitialState(){
      if(this.selfUserDetailData != undefined){
        this.avatar = this.selfUserDetailData.profile.avatar
        this.thumbnail = this.selfUserDetailData.profile.thumbnail
      }
    }
  },
  mounted () {
    this.getSelfUserInfoMethod
    this.getInitialState
  },
  methods: {
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      let cropperImg = this.cropImg;
      cropperImg = cropperImg.replace(/^data:image\/(png|jpg);base64,/, "")
      let data = {
        "avatar": cropperImg,
        "filename":this.filename
      }
      this.userImgLoading = true
      axios.patch(profileAvatarApiUrl, data).then((res) => {
        this.imgSrc = '';
        toast.success(res.data.msg);
        this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
          this.thumbnail = res.data.data.profile.thumbnail
          this.avatar = res.data.data.profile.avatar;
        })
        this.userImgLoading = false
      }).catch((err)=>{
        toast.error('Media not supported');
        this.userImgLoading = false
      })
      return
    },
    cancelCrop() {
      this.$refs.cropper.reset();
      this.imgSrc = '';
      this.filename = '';
    },
    setImage(e) {
      const file = e.target.files[0];
      this.filename = file.name;
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    closeUpdateProfilePhotoModal(){
      bus.$emit('updateProfilePhotoModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    removeImage() {
      axios.delete(profileAvatarApiUrl).then((res) => {
        toast.success(res.data.msg);
        if (res.status == '200') {
          this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
            this.thumbnail = res.data.data.profile.thumbnail
            this.avatar = res.data.data.profile.avatar;
          })
          return
        }
      }).catch((err)=>{
      })
    },
  },
  watch:{
    getSelfUserInfoMethod() {
      return this.$store.getters.selfUserDetailGetters
    },
    getInitialState(){
      if(this.selfUserDetailData != undefined){
        this.avatar = this.selfUserDetailData.profile.avatar
        this.thumbnail = this.selfUserDetailData.profile.thumbnail
      }
    }
  }
}
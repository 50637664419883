/*eslint-disable */
import {bus} from '@/main';  
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import toast from '@/services/toast';
export default {
  name: 'alert-upload-document-Id',
  components: {
    VueCropper
  },
  props: ['documentIdProofDATA'],
  data () {
    return {
      uploadFrontFile:null,
      uploadFrontImage:'',
      uploadFrontCropImage:'',
      uploadBackFile:null,
      uploadBackImage:'',
      uploadBackCropImage:'',
      id_proof_filename:'',
      submitted:false,
      front_id_proof_img:'',
      back_id_proof_img:''
    }
  },
  computed: {

  },
  mounted () {
    this.front_id_proof_img = this.documentIdProofDATA.front_id_proof
    this.uploadFrontImage = this.documentIdProofDATA.front_id_proof 
    this.uploadFrontCropImage = this.documentIdProofDATA.front_id_proof 
    this.back_id_proof_img = this.documentIdProofDATA.back_id_proof
    this.uploadBackImage = this.documentIdProofDATA.back_id_proof 
    this.uploadBackCropImage = this.documentIdProofDATA.back_id_proof 
  },
  methods: {
    onUploadFrontFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.uploadFrontImageMethod(files[0]);
    },
    uploadFrontImageMethod(file) {
      this.id_proof_filename = file.name
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.uploadFrontCropImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeUploadFrontImage(){
      this.uploadFrontCropImage = ''
      this.uploadFrontImage = '';
      this.id_proof_filename = '';
      this.uploadFrontFile = null
    },
    cropFrontImage() {
      this.uploadFrontCropImage = this.$refs.uploadFrontImage.getCroppedCanvas().toDataURL();
      let cropperImg = this.uploadFrontCropImage;
      this.uploadFrontImage = cropperImg.replace(/^data:image\/(png|jpg);base64,/, "")
    },
    cancelCropFrontImage() {
      this.$refs.uploadFrontImage.reset();
      this.uploadFrontCropImage = ''
      this.id_proof_filename = '';
      this.uploadFrontImage = '';
      this.uploadFrontFile = null
    },
    onUploadBackFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.uploadBackImageMethod(files[0]);
    },
    uploadBackImageMethod(file) {
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.uploadBackCropImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeUploadBackImage(){
      this.uploadBackCropImage = ''
      this.uploadBackImage = '';
      this.uploadBackFile = null
    },
    cropBackImage() {
      let cropperImg = this.$refs.uploadBackCropImage.getCroppedCanvas().toDataURL();
      this.uploadBackImage = cropperImg.replace(/^data:image\/(png|jpg);base64,/, "")
    },
    cancelCropBackImage() {
      this.$refs.uploadBackCropImage.reset();
      this.uploadBackCropImage = ''
      this.uploadBackImage = '';
      this.uploadBackFile = null
    },
    uploadDocumentIDPoupEnabled(){
      bus.$emit('uploadDocumentIDBus', false)
      document.body.classList.remove('popup_open_body')
    },
    getSelfUserInfoAgainFun(){
      this.$store.dispatch('getSelfUserInfoApiFun')
    },
    uploadDocumentIdSubmitMethod(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true
          let data = {
            'front_id_proof': this.uploadFrontImage,
            'back_id_proof':this.uploadBackImage,
            'id_proof_filename':this.id_proof_filename
          }
          this.$store.dispatch('uploadIdProofAction', data).then((res) => {
            this.submitted = false
            toast.success(res.data.msg);
            this.getSelfUserInfoAgainFun();
            this.uploadDocumentIDPoupEnabled();
          }).catch((err)=>{
            this.submitted = false
            toast.error('Media not supported');
          })
          return
        }
      })
    }
  }
}
/*eslint-disable */

import {bus} from '@/main';
import countries from '@/services/country.json';
import mobileNumberUpdated from '../mobile-number-updated/index.vue';
import toast from '@/services/toast';
import uploadDocumentId from '../alert-upload-document-Id/index.vue';

export default {
  name: 'update-user-details-form',
  components: {
    'mobile-number' : mobileNumberUpdated,
    'upload-document-id' : uploadDocumentId
  },
  props: ['updatePatientInfoDATA'],
  data () {
    return {
      country:'',
      city:'',
      height:'',
      date:'',
      blood_type:'',
      weight:'',
      submitted:false,
      countryList:[],
      country:'',
      city:'',
      address:'',
      mobileNoUpdatePopup:false,
      phone: {
        number: '',
        countryCode: ''
      },
      fullMobileCountryCode:'',
      minDatetime: new Date(),
      gender:'',
      uploadDocumentIDState:false,
      documentIdProofData:null
    }
  },
  computed: {

  },
  mounted () {
    this.height = this.updatePatientInfoDATA.profile.height || ''
    this.weight = this.updatePatientInfoDATA.profile.weight || ''
    this.date = this.updatePatientInfoDATA.dob || ''
    this.gender = this.updatePatientInfoDATA.gender || ''
    this.blood_type = this.updatePatientInfoDATA.profile.blood_type || ''
    this.country = this.updatePatientInfoDATA.profile.country || ''
    this.city = this.updatePatientInfoDATA.profile.city || ''
    this.address = this.updatePatientInfoDATA.profile.address || ''
    this.phone.countryCode = this.updatePatientInfoDATA.profile.mobile_country_code
    this.fullMobileCountryCode = this.phone.countryCode + ' ' + this.updatePatientInfoDATA.profile.mobile
    // if(this.updatePatientInfoDATA && this.updatePatientInfoDATA.profile){
    //   if(!this.updatePatientInfoDATA.profile.back_id_proof && !this.updatePatientInfoDATA.profile.front_id_proof){
    //     this.uploadDocumentIDPoupEnabled(this.updatePatientInfoDATA.profile)
    //   }
    // }
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    bus.$on('mobileNoUpdatePopupBus', (data) => {
      this.mobileNoUpdatePopup = data
    });
    bus.$on('uploadDocumentIDBus', (data) => {
      this.uploadDocumentIDState = data
    });
  },
  methods: {
    uploadDocumentIDPoupEnabled(data){
      this.documentIdProofData = data
      document.body.classList.add('popup_open_body')
      this.uploadDocumentIDState = true
    },
    updatedProfileNumber() {
      this.mobileNoUpdatePopup = true
      document.body.classList.add('popup_open_body')
    },
    getSelfUserInfoAgainFun(){
      this.$store.dispatch('getSelfUserInfoApiFun')
    },
    updateUserInfoFormBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
          height: this.height,
          dob: this.date,
          weight: this.weight,
          blood_type: this.blood_type,
          country:this.country,
          city:this.city,
          address:this.address
          }
         this.$store.dispatch('patientInfoUpdateAction', data).then((res) => {
          toast.success(res.data.msg);
          this.getSelfUserInfoAgainFun()
          this.$router.push('/dashboard/');
          this.submitted =  false
         }).catch((err) => {
          toast.error(err.data.msg);
          this.submitted =  false
         })
         return;
        }
      })
    },
  }
}
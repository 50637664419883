import { render, staticRenderFns } from "./alert-upload-document-Id.html?vue&type=template&id=69fee093&scoped=true&external"
import script from "./alert-upload-document-Id.js?vue&type=script&lang=js&external"
export * from "./alert-upload-document-Id.js?vue&type=script&lang=js&external"
import style0 from "./alert-upload-document-Id.scss?vue&type=style&index=0&id=69fee093&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69fee093",
  null
  
)

export default component.exports